<template>
  <div>
    <main id="content"
          class="broker-content">
      <HeaderBrokerComponent />
      <section class="dashboard-section">
        <div class="container">
          <div class="row align-items-center position-relative justify-content-between">
            <div class="col-lg-4 col-md-4">
              <h3 class="m-0">
                Reservations
              </h3>
            </div>
            <div class="col-auto ml-md-auto mt-lg-1 mt-md-0 mt-2">
              <button class="btn btn-outline-primary"
                      @click="openModalFilter">
                <i class="icon-equalizer" /> FILTER
              </button>
            </div>
            <div class="modal-filter d-none"
                 id="modalFilter">
              <form>
                <label>Date from:</label>
                <div class="form-group">
                  <datepicker
                    placeholder="Select Date"
                    v-model="checkIn"
                    :clear-button="true" />
                </div>
                <label>Date to:</label>
                <div class="form-group">
                  <datepicker
                    placeholder="Select Date"
                    v-model="checkOut"
                    :clear-button="true" />
                </div>
                <label>Unit name</label>
                <div class="form-group has-icon">
                  <i class="icon-search" />
                  <input
                    v-model="unitName"
                    type="text"
                    class="form-control"
                    placeholder="Name">
                </div>
                <!-- <label>Guest name</label>
                <div class="form-group has-icon">
                  <i class="icon-user" />
                  <input
                    v-model="guestName"
                    type="text"
                    class="form-control"
                    placeholder="Name">
                </div> -->
                <button type="button"
                        @click="getReservations">
                  Apply Filters
                </button>
              </form>
            </div>
          </div>
          <div class="row">
            <div class="col-12 mt-4">
              <form class="myTrip-navForm">
                <label :class="{ labelContainer:true, active: status == '1' }">
                  <input v-model="status"
                         @change="getReservations"
                         value="1"
                         type="radio">
                  <span class="checkmark" />
                  <span class="name">Upcoming</span>
                </label>
                <label :class="{ labelContainer:true, active: status == '4' }">
                  <input v-model="status"
                         @change="getReservations"
                         value="4"
                         type="radio">
                  <span class="checkmark check" />
                  <span class="name">Completed</span>
                </label>
                <label :class="{ labelContainer:true, active: status == '3' }">
                  <input v-model="status"
                         @change="getReservations"
                         value="3"
                         type="radio">
                  <span class="checkmark canceled" />
                  <span class="name">Canceled</span>
                </label>
              </form>
            </div>
            <div class="col-12">
              <div class="accordion-table">
                <!-- title of accordion -->
                <ul class="head-accordion">
                  <li>PROPERTY</li>
                  <li>DATES</li>
                  <li>NIGHTS</li>
                  <li>$ RENT</li>
                  <li>$ EARNINGS</li>
                  <li>STATUS</li>
                </ul>
                <!-- accordion -->
                <vsa-list>
                  <vsa-item v-for="(reservation, index) in cReservations"
                            :key="index">
                    <vsa-heading>
                      <!-- property -->
                      <div class="item-information-accordion-li">
                        <div class="d-flex align-items-center">
                          <div class="thumb">
                            <img :src="reservation.property_image.thumbnail">
                          </div>
                          <h6>{{ reservation.property_name }}<small>{{ reservation.property_location }}</small></h6>
                        </div>
                      </div>
                      <!-- dates -->
                      <div class="item-information-accordion-li">
                        {{ reservation.check_in }} - {{ reservation.check_out }}
                      </div>
                      <!-- nights -->
                      <div class="item-information-accordion-li">
                        {{ reservation.nights }}
                      </div>
                      <!-- total -->
                      <div class="item-information-accordion-li">
                        ${{ reservation.total| numFormat('0,0.00') }}
                      </div>
                      <!-- earnings -->
                      <div class="item-information-accordion-li">
                        ${{ reservation.earnings| numFormat('0,0.00') }}
                      </div>
                      <!-- status -->
                      <div class="item-information-accordion-li status">
                        {{ reservation.status }}
                      </div>
                      <div class="item-information-accordion-li">
                        <i class="icon-cheveron-down" />
                      </div>
                    </vsa-heading>
                    <vsa-content>
                      <div class="cont">
                        <div class="content-description">
                          <h6><small>Channel</small></h6>
                          <p>{{ reservation.channel }}</p>
                        </div>
                        <div class="content-description">
                          <h6><small>Price / Night</small></h6>
                          <p>${{ (reservation.total / reservation.nights)| numFormat('0,0.00') }}</p>
                        </div>
                        <div class="content-description"
                             v-if="reservation.booking_earnings">
                          <h6><small>Booking earnings</small></h6>
                          <p>${{ reservation.booking_earnings| numFormat('0,0.00') }}</p>
                        </div>
                        <div class="content-description"
                             v-if="reservation.plus_booking_earnings">
                          <h6><small>Self Booking earnings</small></h6>
                          <p>${{ reservation.plus_booking_earnings| numFormat('0,0.00') }}</p>
                        </div>
                        <div class="content-description"
                             v-if="reservation.other_earnings">
                          <h6><small>Other earning</small></h6>
                          <p>${{ reservation.other_earnings| numFormat('0,0.00') }}</p>
                        </div>
                        <!-- <div class="content-description">
                          <h6><small>Guest</small></h6>
                          <p>{{ reservation.guest }}</p>
                        </div> -->
                      </div>
                    </vsa-content>
                  </vsa-item>
                </vsa-list>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <myFooter />
  </div>
</template>

<script>
import { VsaList, VsaItem, VsaHeading, VsaContent } from 'vue-simple-accordion';
import 'vue-simple-accordion/dist/vue-simple-accordion.css';
import Datepicker from 'vuejs-datepicker';
import HeaderBrokerComponent from '../../components/broker/Header.vue';

export default {
  components: {
    VsaList,
    VsaItem,
    VsaHeading,
    VsaContent,
    HeaderBrokerComponent,
    Datepicker,
  },
  name: 'Reservations',
  data() {
    return {
      reservations: null,
      checkIn: null,
      checkOut: null,
      status: 1,
      unitName: null,
      guestName: null,
    };
  },
  created() {
    this.getReservations();
  },
  computed: {
    cReservations() {
      return this.reservations;
    }
  },
  methods: {
    openModalFilter() {
      const modalFilter = document.getElementById('modalFilter');
      modalFilter.classList.toggle('d-none');
    },
    getReservations() {
      this.reservations = null;
      let queryString = `?status=${this.status}`;
      if (this.unitName) {
        queryString += `&unit_name=${this.unitName}`;
      }
      if (this.guestName) {
        queryString += `&guest_name=${this.guestName}`;
      }
      if (this.checkIn) {
        const dateFrom = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(this.checkIn);
        queryString += `&date_from=${dateFrom}`;
      }
      if (this.checkOut) {
        const dateTo = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(this.checkOut);
        queryString += `&date_to=${dateTo}`;
      }
      this.$axios.get(`/v1/brokers/bookings/${queryString}`).then((response) => {
        this.reservations = response.data;
      });
    },
  }
};
</script>

<style>
  .accordion-table h6 {
    max-width: 140px;
  }
</style>
